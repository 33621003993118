import {
	Marker,
	GoogleMap,
	// InfoWindow,
	withScriptjs,
	withGoogleMap,
	// DirectionsRenderer,
} from 'react-google-maps'

function GoogleMapFinca() {
	return (
		<GoogleMap
			defaultZoom={10}
			defaultCenter={{ lat: 38.690836, lng: 0.071771 }}
			mapTypeId="hybrid"
		>
			<Marker
				position={{ lat: 38.690836, lng: 0.071771 }}
				title="Finca Los Monteros"
			/>
		</GoogleMap>
	);
}

export default withScriptjs(withGoogleMap(GoogleMapFinca));