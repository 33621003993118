const text =
  {
    app: {
      home: {
        nl: "Home",
        fr: "Home",
        en: "Home",
        de: "Home",
        es: "Home"
      },
      description: {
        nl: "Beschrijving",
        fr: "Description",
        en: "Description",
        de: "Beschreibung",
        es: "Descripcion"
      },
      pictures: {
        nl: "Foto's",
        fr: "Photos",
        en: "Pictures",
        de: "Bilder",
        es: "Fotos"
      },
      video: {
        nl: "Video",
        fr: "Video",
        en: "Video",
        de: "Video",
        es: "Video"
      },
      access: {
        nl: "Ligging",
        fr: "Acces",
        en: "Access",
        de: "Wie erreichen",
        es: "Acceso"
      },
      contact: {
        nl: "Contact",
        fr: "Contact",
        en: "Contact",
        de: "Kontakt",
        es: "Contacto"
      },
      links: {
        nl: "Links",
        fr: "Liens",
        en: "Links",
        de: "Links",
        es: "Links"
      },
      calendar: {
        nl: "Kalender",
        fr: "Calendrier",
        en: "Calendar",
        de: "Kalender",
        es: "Calendario"
      },
      lang: {
        nl: "Nederlands",
        fr: "Français",
        en: "English",
        de: "Deutsch",
        es: "Español "
      }
    },
    home: {
      meta: {
        title: {
          nl: "Finca Los Monteros - Benissa - Spain",
          fr: "Finca Los Monteros - Benissa - Spain",
          en: "Finca Los Monteros - Benissa - Spain",
          de: "Finca Los Monteros - Benissa - Spain",
          es: "Finca Los Monteros - Benissa - Spain"
        },
        description: {
          nl: "Charmante en authentieke finca, gelegen tussen de kust van Calpe en het bergdorpje Benissa, tot 10 personen",
          fr: "Finca charmante et authentique, située entre la côte de Calpe et le petit village de Benissa, jusqu'à 10 personnes",
          en: "Charming and authentic finca, situated between the coast of Calpe and the little mountain village of Benissa, up to 10 persons",
          de: "Sehr authentische Finca, gelegen zwischen der Küste Calpes und dem Bergdorf Benissas, bis 10 Personen",
          es: "Finca encantadora y auténtica, situado entre la costa de Calpe y el pueblo de montaña Benissa, hasta 10 personas"
        }
      }
    },
    description: {
      meta: {
        title: {
          nl: "Finca Los Monteros - Benissa - Spain - beschrijving",
          fr: "Finca Los Monteros - Benissa - Spain - description",
          en: "Finca Los Monteros - Benissa - Spain - description",
          de: "Finca Los Monteros - Benissa - Spain - Beschreibung",
          es: "Finca Los Monteros - Benissa - Spain - Descripcion"
        },
        description: {
          nl: "",
          fr: "",
          en: "",
          de: "",
          es: ""
        }
      },
    },
    pictures: {
      meta: {
        title: {
          nl: "Finca Los Monteros - Benissa - Spain - foto's",
          fr: "Finca Los Monteros - Benissa - Spain - photos",
          en: "Finca Los Monteros - Benissa - Spain - pictures",
          de: "Finca Los Monteros - Benissa - Spain - Bilder",
          es: "Finca Los Monteros - Benissa - Spain - fotos"
        },
        description: {
          nl: "",
          fr: "",
          en: "",
          de: "",
          es: ""
        }
      },
      living: {
        nl: "woonkamer",
        fr: "salle de séjour",
        en: "living room",
        de: "Wohnzimmer",
        es: "sala"
      },
      kitchen: {
        nl: "keuken",
        fr: "cuisine",
        en: "kitchen",
        de: "Küche",
        es: "cocina"
      },
      bedroom1: {
        nl: "slaapkamer 1 (2p)",
        fr: "chambre à coucher 1 (2p)",
        en: "bedroom 1 (2p)",
        de: "Schlafzimmer 1 (2p)",
        es: "dormitorio 1 (2p)"
      },
      bedroom2: {
        nl: "slaapkamer 2 (4p)",
        fr: "chambre à coucher 2 (4p)",
        en: "bedroom 2 (4p)",
        de: "Schlafzimmer 2 (4p)",
        es: "dormitorio 2 (4p)"
      },
      bedroom3: {
        nl: "slaapkamer 3 (2p)",
        fr: "chambre à coucher 3 (2p)",
        en: "bedroom 3 (2p)",
        de: "Schlafzimmer 3 (2p)",
        es: "dormitorio 3 (2p)"
      },
      bedroom4: {
        nl: "slaapkamer 4 (2p)",
        fr: "chambre à coucher 4 (2p)",
        en: "bedroom 4 (2p)",
        de: "Schlafzimmer 4 (2p)",
        es: "dormitorio 4 (2p)"
      },
      bathroom1: {
        nl: "badkamer 1",
        fr: "salle de bains 1",
        en: "bathroom 1",
        de: "Bad 1",
        es: "cuarto de baño 1"
      },
      bathroom2: {
        nl: "badkamer 2",
        fr: "salle de bains 2",
        en: "bathroom 2",
        de: "Bad 2",
        es: "cuarto de baño 2"
      },
      bathroom3: {
        nl: "badkamer 3",
        fr: "salle de bains 3",
        en: "bathroom 3",
        de: "Bad 3",
        es: "cuarto de baño 3"
      },
    },
    access: {
      meta: {
        title: {
          nl: "Finca Los Monteros - Benissa - Spain - ligging",
          fr: "Finca Los Monteros - Benissa - Spain - acces",
          en: "Finca Los Monteros - Benissa - Spain - access",
          de: "Finca Los Monteros - Benissa - Spain - Wie erreichen",
          es: "Finca Los Monteros - Benissa - Spain - acceso"
        },
        description: {
          nl: "",
          fr: "",
          en: "",
          de: "",
          es: ""
        }
      },
      line1: {
        nl: "Benissa bevindt zich pal tussen de luchthavens van Alicante en Valencia op afrit 63 van de AP7 – E15 (= autosnelweg). Van beide luchthavens staat u op ± 50 minuten met de wagen in Benissa. Vanaf afrit 63 staat u op 10 minuten aan de Finca.",
        fr: "Benissa se trouve juste au milieu des aéroports de Alicante et Valencia, à la sortie n° 63 de l’autoroute AP7-E15. Cela vous prend ± 50 minutes en voiture jusqu’à Benissa et ensuite ± 10 minutes à la Finca.",
        en: "Benissa lies in the middle between the airports of Alicante and Valencia, on exit n° 63 of the AP7-E15-highway. From either airport to Benissa it takes ± 50 minutes by car, and than ± 10 minutes more to the Finca.",
        de: "Benissa befindet sich in der Mitte zwischen den Fughäfen Alicantes und Valencias, an der Ausfahrt n° 63 der AP7-E15 Autobahn. Von dem Flughafen nach Benissa fahren Sie ± 5 Minuten im Auto, und dann noch ± 10 Minuten nach die Finca.",
        es: "Benissa se encuentra en el medio entre los aeropuertos de Alicante y Valencia, en la sortida n° 63 de la autopista AP7-E15. Del aeropuerto hasta Benissa es ± 50 minutos en coche y entonces ± 10 minutos hasta la Finca rústica."
      },
      line2: {
        nl: "U ontvangt een gedetailleerde wegbeschrijving voor uw vertrek.",
        fr: "Vous recevrez un plan détaillé avant votre départ.",
        en: "You’ll receive a detailed roadmap before your departure.",
        de: "Sie bekommen einen detaillierten Roadmap vor Ihrer Abfahrt.",
        es: "Usted recibirá un itinerario detallado antes de su salida."
      }
    },
    contact: {
      meta: {
        title: {
          nl: "Finca Los Monteros - Benissa - Spain - contact",
          fr: "Finca Los Monteros - Benissa - Spain - contact",
          en: "Finca Los Monteros - Benissa - Spain - contact",
          de: "Finca Los Monteros - Benissa - Spain - Kontakt",
          es: "Finca Los Monteros - Benissa - Spain - contacto"
        },
        description: {
          nl: "",
          fr: "",
          en: "",
          de: "",
          es: ""
        }
      },
    },
    calendar: {
      meta: {
        title: {
          nl: "Finca Los Monteros - Benissa - Spain - kalender",
          fr: "Finca Los Monteros - Benissa - Spain - calendrier",
          en: "Finca Los Monteros - Benissa - Spain - calendar",
          de: "Finca Los Monteros - Benissa - Spain - Kalender",
          es: "Finca Los Monteros - Benissa - Spain - calendario"
        },
        description: {
          nl: "",
          fr: "",
          en: "",
          de: "",
          es: ""
        }
      },
      week: {
        nl: "week",
        fr: "semaine",
        en: "week",
        de: "Woche",
        es: "semana",
        description: {
          nl: "Week = zaterdag > zaterdag",
          fr: "Semaine = samedi > samedi",
          en: "Week = Saturday > Saturday",
          de: "Woche = Samstag > Samstag",
          es: "Semana = sábado > sábado"
        }
      },
      arrival: {
        nl: "Aankomst vanaf 16u",
        fr: "Arrivée àpd 16h",
        en: "Arrival from 4PM",
        de: "Anreise ab 16 Uhr",
        es: "Llegada desde las 16h"
      },
      departure: {
        nl: "Vertrek vóór 10u",
        fr: "Départ avant 10h",
        en: "Departure before 10AM",
        de: "Abfahrt vor 10 Uhr",
        es: "Salida antes de las 10h"
      },
      animals: {
        nl: "Wegens allergie van de eigenaars, geen huisdieren toegelaten",
        fr: "A cause d’allergies des propriétaires, pas d’animaux domestiques",
        en: "Due to allergies of the owners, no pets allowed",
        de: "Keine Haustiere erlaubt wegen Aufgrund von Allergien der Besitzer",
        es: "Debido a las alergias de los propietarios, no se permiten mascotas."
      },
      cleaning: {
        nl: "Verplichte opkuis = 120 euro",
        fr: "Nettoyage obligatoire = 120 euro",
        en: "Mandatory cleaning = 120 euro",
        de: "Obligatorische Reinigung = 120 euro",
        es: "Limpieza obligatoria = 120 euro"
      },
      towels: {
        nl: "Laken + handdoekenpakket = 15 euro per persoon",
        fr: "Forfait drap + serviette = 15 euros par personne",
        en: "Sheet + towel package = 15 euros per person",
        de: "Bettwäsche + Handtuchpaket = 15 Euro pro Person",
        es: "Paquete de sábanas + toallas = 15 euros por persona"
      },
      utilities: {
        nl: "Water, elektriciteit en gas inbegrepen",
        fr: "Eau, électricité et gaz inclus",
        en: "Water, electricity and gas included",
        de: "Wasser, Strom und Gas inklusive",
        es: "Agua, luz y gas incluidos"
      },
      specials: {
        nl: "Afwijkingen mogelijk",
        fr: "Dérogations possible",
        en: "Derogations are possible",
        de: "Abweichungen möglich",
        es: "Desviaciones posibles"
      },
      discount: {
        nl: "Korting voor langere periodes",
        fr: "Réduction possible pour séjour plus longues",
        en: "Discounts possible for longer stays",
        de: "Rabatt für längere Zeit",
        es: "Descuento por períodos más largos"
      },
      contact: {
        nl: "Contacteer ons met specifieke vragen",
        fr: "Contactez-nous avec vos questions spécifiques",
        en: "Contact us for specific questions",
        de: "Kontaktieren Sie uns bei konkreten Fragen",
        es: "Contáctenos con preguntas específicas"
      },
      book: {
        nl: "Boek hier!",
        fr: "Réservez ici!",
        en: "Book here!",
        de: "Hier buchen!",
        es: "Reserva aquí!"
      }
    },
    links: {
      meta: {
        title: {
          nl: "Finca Los Monteros - Benissa - Spain - links",
          fr: "Finca Los Monteros - Benissa - Spain - liens",
          en: "Finca Los Monteros - Benissa - Spain - links",
          de: "Finca Los Monteros - Benissa - Spain - links",
          es: "Finca Los Monteros - Benissa - Spain - links"
        },
        description: {
          nl: "",
          fr: "",
          en: "",
          de: "",
          es: ""
        },
      },
      flights: {
        title: {
          nl: "Vluchten",
          fr: "Vols",
          en: "Flights",
          de: "Flüge",
          es: "Vuelos"
        },
        links: [
          {
            name: "TUI fly",
            url: "https://www.tuifly.be"
          },
          {
            name: "Brussels Airlines",
            url: "https://www.brusselsairlines.com"
          },
          {
            name: "Vueling",
            url: "https://www.vueling.com"
          },
          {
            name: "Ryanair",
            url: "https://www.ryanair.com"
          },
          {
            name: "Alicante Airport",
            url: "https://www.aena.es/en/alicante-elche.html"
          },
          {
            name: "Valencia Airport",
            url: "https://www.airport-valencia.com"
          }
        ],
      },
      carRental: {
        title: {
          nl: "Autoverhuur",
          fr: "Location voiture",
          en: "Car rental",
          de: "Mietwagen",
          es: "Alquiler coches"
        },
        links: [
          {
            name: "Goldcar",
            url: "https://www.goldcar.es"
          },
          {
            name: "Europcar",
            url: "https://www.europcar.com"
          },
          {
            name: "Avis",
            url: "https://www.avis.com"
          }
        ],
      },
      pointsOfInterest: {
        title: {
          nl: "Bezienswaardigheden",
          fr: "Points d'intérêt",
          en: "Points of interest",
          de: "Points of interest",
          es: "Puntos de interés"
        },
        links: [
          {
            name: "Calpe",
            url: "https://www.calpe.es"
          },
          {
            name: "Benissa",
            url: "https://www.benissa.net"
          },
          {
            name: "Altea",
            url: "https://www.altea.es"
          },
          {
            name: "Teulada",
            url: "http://www.teuladamoraira.com.es"
          },
          {
            name: "Xabia",
            url: "https://xabia.org"
          },
          {
            name: "Vall de Pop",
            url: "http://valldepop.es"
          },
          {
            name: "Valencia",
            url: "https://www.visitvalencia.com"
          },
          {
            name: "Alicante",
            url: "https://www.alicanteturismo.com"
          }
        ]
      }
    }
  }

export default text;
