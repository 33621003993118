import { useMetaTags } from 'react-metatags-hook'
import styles from './Access.module.css'
import GoogleMapFinca from "./GoogleMapFinca";
import text from '../i8n/text.js';

function Access({ language }) {
	useMetaTags({
		title: text.access.meta.title[language],
		description: text.access.meta.description[language],
	}, [language]);
	return (
		<div>
			{/*<img className={styles.topImage} src="/images/buiten_01_top.jpg" alt="" />*/}
			<div className={styles.container}>
				<GoogleMapFinca
					googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCl21QnJxrkkZFCtXXqUER9ux_O9tJ11es&sensor=false"
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: `600px` }} />}
					mapElement={<div style={{ height: `100%` }} />}
				/>
  			<p>{text.access.line1[language]}</p>
	  		<p>{text.access.line2[language]}</p>
			</div>
		</div>
	);
}

export default Access;