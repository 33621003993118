import { useMetaTags } from 'react-metatags-hook'
import styles from './Contact.module.css'
import text from "../i8n/text";

function Contact({ language }) {
	useMetaTags({
		title: text.contact.meta.title[language],
		description: text.contact.meta.description[language],
	}, [language]);
	return (
		<div>
			<img className={styles.topImage} src="./images/buiten_01_top.jpg" alt="" />
			<div className={styles.container}>
				<img src="./images/email.png" alt="" /><br />
				<a href="mailto:info@fincalosmonteros.es">info@fincalosmonteros.es</a><br /><br />
				<img src="./images/telephone.png" alt="" /><br />
				+32 477/56.15.69<br /><br />
				<img src="./images/facebook.png" alt="" /><br />
				<a href="https://www.facebook.com/FincaLosMonteros" target="_blank" rel="noreferrer">Facebook</a><br /><br />
				<img src="./images/messenger.png" alt="" /><br />
				<a href="https://www.messenger.com/t/280706935407696" target="_blank" rel="noreferrer">Messenger</a>
			</div>
		</div>
	);
}

export default Contact;