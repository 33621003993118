import {
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import cn from 'classnames';
import Home from './components/Home';
import Pictures from './components/Pictures';
import Video from './components/Video';
import Access from './components/Access';
import Calendar from './components/Calendar';
import Description from './components/Description';
import Contact from './components/Contact';
import Links from './components/Links';
import text from './i8n/text.js';
import styles from './App.module.css';

// import {useWindowSize} from '@react-hook/window-size'

function App() {
  const { pathname } = useLocation();
  // const [width, height] = useWindowSize()

  const link = (page) => (
    <li className={styles.menu}>
      <Link className={cn(styles.menuLink, currentPage === page ? styles.menuLinkSelected : '')} to={`./${page}${pathSuffix}`}>
        {page === '' ? text.app.home[language] : text.app[page][language]}
      </Link>
    </li>
  );

  let language = 'nl';
  let currentPage = pathname.split('/').pop();
  if (pathname.search('_fr') > -1) {
    language = 'fr';
    currentPage = currentPage.slice(0, -3);
  } else if (pathname.search('_en') > -1) {
    language = 'en';
    currentPage = currentPage.slice(0, -3);
  } else if (pathname.search('_de') > -1) {
    language = 'de';
    currentPage = currentPage.slice(0, -3);
  } else if (pathname.search('_es') > -1) {
    language = 'es';
    currentPage = currentPage.slice(0, -3);
  }
  const pathSuffix = language === 'nl' ? '' : `_${language}`;
  return (
    <div>
  	    <div className={styles.header}>
		    <div className={styles.headerContainer}>
			    <div className={styles.titleContainer}>
            <img className={styles.logo} src='./images/Logo Finca Los Monteros 1.jpg' alt="" />
		        <a href={`./${pathSuffix}`}>
    			    <h3 className={styles.title}>
							  Finca Los Monteros
							</h3>
						  <span className={styles.subtitle}>Benissa - Spain</span>
		        </a>
	  		  </div>
          <nav className={styles.navbar}>
            <ul className={styles.menuContainer}>
              {link('')}
              {link('description')}
              {link('pictures')}
              {link('video')}
              {link('access')}
              {link('contact')}
              {link('links')}
            </ul>
            <Link className={cn(styles.menu, styles.menuCalendar, styles.menuLink)} to={`./calendar${pathSuffix}`}>
              {text.app.calendar[language]}
            </Link>
            <div className={styles.language}>
              <a className={styles.languageItem} href={`./${currentPage}`}>
                NL
              </a>
              <a className={styles.languageItem} href={`./${currentPage}_fr`}>
                FR
              </a>
              <a className={styles.languageItem} href={`./${currentPage}_en`}>
                EN
              </a>
              <a className={styles.languageItem} href={`./${currentPage}_de`}>
                DE
              </a>
              <a className={styles.languageItem} href={`./${currentPage}_es`}>
                ES
              </a>
            </div>
          </nav>
        </div>
		</div>
	    <div className={styles.body}>
        <Switch>
          <Route path="*/description*">
            <Description language={language} />
          </Route>
          <Route path="*/pictures*">
            <Pictures language={language} />
          </Route>
          <Route path="*/video*">
            <Video language={language} />
          </Route>
          <Route path="*/access*">
            <Access language={language} />
          </Route>
          <Route path="*/calendar*">
            <Calendar language={language} />
          </Route>
          <Route path="*/contact*">
            <Contact language={language} />
          </Route>
          <Route path="*/links*">
            <Links language={language} />
          </Route>
          <Route path="*/">
            <Home language={language} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;