import axios from "axios";

const CALENDAR_ID = 'c6lh61n1r2eurhg4iknh277vjg@group.calendar.google.com';
const API_KEY = 'AIzaSyDmSk9nI70M6t0Rc77L55yih-A9yENP-j8';

export async function getEvents(timeMin, timeMax) {
  const res = await axios.get(
	  `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events`, 
		{ params: { key: API_KEY, timeMin, timeMax, singleEvents: true, orderBy: "startTime" } }
	);    
	
	return res.data.items;
}